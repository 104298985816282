import React from "react";
import dateFormat from "dateformat";

import { promiseTimeout } from "../utils/async";

import { sha256 } from "../utils/sha256";

export function capiDateFromString(s) {
  if (typeof s === "string") {
    const cached = dateCache.get(s);
    if (cached)
      return cached;
    
    const date = s.substring(0, 10);
    const x = new Date(date);
    x.hasTime = false;
    
    if (s.length > 10) {
      const hours = s.substring(11, 13);
      const minutes = s.substring(14, 16);
      const secs = s.substring(17, 19);
      x.setUTCHours(hours, minutes, secs);
      x.hasTime = true;
    }
    
    x.setTime = datesAreImmutable;
    x.setDate = datesAreImmutable;
    x.setUTCDate = datesAreImmutable;
    x.setFullYear = datesAreImmutable;
    x.setUTCFullYear = datesAreImmutable;
    x.setMonth = datesAreImmutable;
    x.setUTCMonth = datesAreImmutable;
    x.setHours = datesAreImmutable;
    x.setUTCHours = datesAreImmutable;
    x.setMinutes = datesAreImmutable;
    x.setUTCMinutes = datesAreImmutable;
    x.setSeconds = datesAreImmutable;
    x.setUTCSeconds = datesAreImmutable;
    x.setMilliseconds = datesAreImmutable;
    x.setUTCMilliseconds = datesAreImmutable;
    
    if (dateCache.size > 1000) {
      let n = 10;
      for (const first of dateCache.keys()) {
        dateCache.delete(first);
        if (n-- <= 0) break;
      }
    }
    dateCache.set(s, x);
    
    return x;
  }
  else if (s instanceof Date)
    return s;
  else
    throw new Error(`capiDateFromString expects a string, not: ${typeof s}`);
}

const dateCache = new Map();

function datesAreImmutable() {
  throw new Error("Date objects returned from capiDateFromString are to be treated as immutable!");
}

export function capiDateToString(d) {
  const v = typeof d === "undefined" ? new Date() : d;
  // true na końcu powoduje konwersję do UTC
  return dateFormat(v, "yyyy-mm-dd HH:MM:ss.l000", true);
}

export function sessionAuth(ssid, sskey) {
  if (sskey)
    return [102, ssid, sskey];
  else
    return [112, ssid, window.bootstrap.domain];
}

const fetchHeaders = {
  "Content-Type": "application/json",
  "X-Strix-User-Agent": `SowaWeb/${process.env.WS_VER}.${process.env.WS_BLD}`
};

const fetch = window.fetch;
const toJson = JSON.stringify;
const devel = !!window.bootstrap.devel;

const NETWORK_ERROR = "Wystąpił problem w komunikacji z serwerem. Prosimy spróbować później."; // w nowym kliencie przetłumaczymy

export class CapiClient {
  constructor(url, { auth, mode, lang, log, wait } = { mode: 1 }) {
    this.url = url;
    this.auth = auth;
    this.mode = mode;
    this.lang = lang;
    this.log = log;
    this.wait = wait;
    this._onExecuted = [];
    this._doRetry = mode > 0;
  }

  onExecuted(callback) {
    if (this._onExecuted.indexOf(callback) < 0) {
      let cbs = this._onExecuted.slice();
      cbs.push(callback);
      this._onExecuted = cbs;
    }
  }

  emitOnExecuted(commands) {
    const cbs = this._onExecuted;
    const cbl = cbs.length;
    const errors = [];

    for (let cbi = 0; cbi < cbl; cbi++) {
      try {
        cbs[cbi].call(this, commands);
      } catch (err) {
        errors.push(err);
      }
    }

    for (let i = 0; i < errors.length; i++) {
      reportError(errors[i]);
    }
  }

  /** Wykonanie zapytania z podanymi jako argumenty komendami */
  async execute(...commands) {
    let url = this.url;
    const auth = this.auth;
    const lang = this.lang;
    const mode = this.mode;
    const wait = this.wait;
    const logConf = this.log;
    const doRetry = this._doRetry;

    let response;
    let commandsLeft = commands;
    let retry = 1;
    
    if (devel) {
      // w FF jak się włączy kolumnę URL w inspektorze, to widać listę komend
      url += "#" + commands.map(cmd => cmd.exec[0]).join(",");
    }
    
    do {
      try {
        response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: fetchHeaders,
          body: toJson({
            auth: auth,
            lang: lang,
            mode: mode,
            log: logConf,
            wait,
            exec: commandsLeft.map(command => command.exec)
          })
        });
      } catch (e) {
        if (e instanceof Error) {
          // na tym etapie łapiemy błędy sieciowe, oznaczmy je by zapobiec ich zdalnemu logowaniu
          e.enduserMessage = NETWORK_ERROR;
          e.dontReport = true;
          e.__ws_richError = {
            message: NETWORK_ERROR,
            nature: "networkError",
          }
        }
        throw e;
      }
      
      if (response.status !== 200) {
        const e = new Error("CAPI status code: " + response.status);
        let message = NETWORK_ERROR;
        
        if ((response.headers.get("content-type") || "").toLowerCase().startsWith("application/json")) {
          const details = await response.json();
          if (details && typeof details.message === "string")
            message = details.message;
        }
        
        e.enduserMessage = message;
        e.dontReport = true;
        e.__ws_richError = {
          message,
          nature: "networkError",
        }
        throw e;
      }
      
      const results = await response.json();
      
      if (!Array.isArray(results) || results.length !== commandsLeft.length) {
        const e = new Error("Malformed CAPI response");
        e.enduserMessage = NETWORK_ERROR;
        e.__ws_richError = {
          message: NETWORK_ERROR,
          nature: "networkError",
        }
        throw e;
      }

      for (let i = 0; i < commandsLeft.length; i++) {
        const command = commandsLeft[i];
        const result = results[i];
        command.report(this, result); // report najpierw, bo w setResult może się wywalić parsowanie
        command.url = this.url;
        command.setResult(result, this);
      }

      if (doRetry) {
        commandsLeft = commandsLeft.filter(
          command =>
            command.retries-- > 0 &&
            (command.status === 0 || command.status === 503)
        );

        if (commandsLeft.length > 0) {
          await promiseTimeout(retry++ * 100);
        } else break;
      } else {
        break;
      }
    } while (true);

    this.emitOnExecuted(commands);

    return commands;
  }

  /** Wykonanie zapytania z jedną podaną komendą */
  executeSingle(command) {
    return this.execute(command).then(commands => commands[0].result);
  }

  copyWith(obj = {}) {
    return { __proto__: Object.getPrototypeOf(this), ...this, ...obj };
  }

  withAuth(...auth) {
    if (this.auth === auth) return this;
    else if (Array.isArray(auth[0])) return this.copyWith({ auth: auth[0] });
    else return this.copyWith({ auth });
  }
}

export class CapiCommand {
  constructor(exec) {
    this.exec = exec;
    this.result = null;
    this.status = 0;
    this.retries = 0;
  }

  retry(n) {
    this.retries = n;
    return this;
  }
  
  fingerprint(auth, lang) {
    const hash = sha256.update(auth || "");
    const isArray = Array.isArray;
    const hasOwn = Object.prototype.hasOwnProperty;
    hash.update(lang || "");
    // rec("", this.exec);
    hash.update(JSON.stringify(this.exec));
    return hash.hex();
    
    // czy jest sens optymizować i nie generować JSONa?
    // chyba żadnych wielkich JSONów nie przetwarzamy tutaj
    
    // function rec(key, obj) {
    //   const tpe = typeof obj;
    //   if (tpe !== "undefined" && typeof obj.toJSON === "function") {
    //     return rec(key, obj.toJSON());
    //   }
    //   switch (tpe) {
    //     case "object":
    //       if (obj === null) {
    //         hash.update("null");
    //       }
    //       else if (isArray(obj)) {
    //         hash.update("arr");
    //         for (let i = 0, L = obj.length; i < L; i++) {
    //           const k = "" + i;
    //           hash.update(k);
    //           rec(k, obj[i]);
    //           hash.update(",");
    //         }
    //       }
    //       else {
    //         hash.update("obj");
    //         for (let k in obj) {
    //           if (hasOwn.call(obj, k)) {
    //             hash.update(k);
    //             rec(k, obj[k]);
    //             hash.update(";");
    //           }
    //         }
    //       }
    //       break;
    //     case "boolean":
    //     case "string":
    //     case "number":
    //       hash.update(obj.toString());
    //       break;
    //     default:
    //       hash.update(tpe);
    //   }
    // }
  }

  setResult(response, client) {
    const result = this.parseResult(response, client);
    this.result = result;
    result && (this.status = result.status);
  }

  parseResult(response, client) {
    let failure = null;

    if (response.status !== undefined)
      response.status = parseInt(response.status, 10);
    else failure = "status is undefined";

    // Sprawdzamy czy status, message oraz reazon się zgadzają
    if (typeof response.status !== "number")
      failure = "status is not a number or is undefined";
    else if (
      response.message !== undefined &&
      typeof response.message !== "string"
    )
      failure = "message is not a string or is undefined";
    else if (
      response.reason !== undefined &&
      typeof response.reason !== "string"
    )
      failure = "reason is not a string or is undefined";
    else if (response.data !== undefined) {
      try {
        // Parsujemy dane
        const data =
          response.status !== 204
            ? this.parseData(response.status, response.data, client)
            : response.data;
        return data === response.data ? response : { ...response, data };
      } catch (err) {
        CapiCommand.reportError(err);
        return {
          status: 500,
          data: response,
          reason: "parse_failure: " + err.message
        };
      }
    } else return response;

    // Zwracamy błąd
    return { status: 500, data: response, reason: "parse_failure: " + failure };
  }

  /** Rzuca wyjątek jeśli komenda nie zakończyła się z jednym z podanych statusów */
  ensure() {
    const args = Array.prototype.slice.call(arguments);
    if (this.result === null) throw new Error("Command hasn't completed yet.");

    if (args.indexOf(this.result.status) === -1 && args.indexOf(this.result.reason) === -1) {
      const err = new Error(
        "Command failed with: " + this.result.status + ", " + this.result.reason
      );
      err.capiStatus = this.result.status;
      err.capiReason = this.result.reason;
      err.capiData   = this.result.data;
      err.enduserMessage = this.result.message;
      err.log = this.result.log;
      err.dontReport = true;
      
      let nature = "appError";
      switch (this.result.status) {
        case 400:
        case 403:
        case 404:
        case 409:
          nature = "userError";
          break;
          
        case 502:
        case 503:
        case 405:
          nature = "networkError";
      }
      
      err.__ws_richError = {
        message: this.result.message || NETWORK_ERROR,
        nature,
        details: <ul>
          <li><b>Komenda:</b> <code>{this.exec[0]}</code></li>
          <li><b>Status:</b> <code>{this.result.status} {this.result.reason}</code></li>
          {this.url && <li><b>Serwer:</b> <code>{this.url}</code></li>}
        </ul>
      }
      throw err;
    }
    
    return this.result;
  }

  static checkValue(val, name, ...types) {
    let t = typeof val;

    if (t === "object") {
      if (val === null) t = "null";
      else if (Array.isArray(val)) t = "array";
    }

    if (types.indexOf(t) < 0)
      throw new Error(`Expected ${types.join("|")} for "${name}", got: ${t}`);

    return val;
  }

  static checkField(obj, field, ...types) {
    let val = obj[field];
    CapiCommand.checkValue(obj[field], field, ...types);
    return val;
  }

  /** Drukowanie loga z formacie zwracanym opcjonalnie przez serwer CAPI */
  report(client, result) {
    if (typeof console === "undefined" || typeof console._log !== "function")
      return;
    
    const log = result.log;
    
    const status = result.status;
    const fallback =
      status >= 500
        ? "<crash>"
        : status >= 400
          ? "<error>"
          : "";
    
    console.groupCollapsed(`${this.exec[0]} 🡒 ${status} ${result.reason || result.message || fallback}`);

    let errToReport;
    try {
      console._log("Server:", client.url, client.auth ? client.auth[0] : "<anon>");
      console._log("Command:", ...this.exec);
      this.dontPrintData || console._log("Result:", result.data);
      
      if (!Array.isArray(log) || log.length === 0)
        return;
      
      try {
        for (let i = 0, L = log.length; i < L; i++) {
          const e = log[i];
          
          let method = console._log;
          switch (e.lv[0]) {
            case "I":
              method = console._info;
              break;
            case "T":
              method = console._debug;
              break;
            case "E":
            case "F":
              method = console._error;
              break;
            case "W":
              method = console._warn;
              break;
            default:
              break;
          }
          method(`%c${e.ts}: ${e.ms} (${e["in"]}:${e.at})`, "font-size: 0.8em");
          
          if (typeof e.ex === "object") CapiCommand.printException(e.ex);
        }
      } catch (err) {
        errToReport = err;
        //gubimy wyjątek, żeby funkcja diagnostyczna nam przypadkiem aplikacji nie wywalała
      }
    }
    finally {
      console.groupEnd();
      errToReport && CapiCommand.reportError(errToReport);
    }
  }

  static printException(ex) {
    console._log(ex.cl + ": " + ex.ms);

    for (let i = 0, L = ex.tb.length; i < L; i++) console._log(ex.tb[i]);

    if (typeof ex.pv === "object") this.printException(ex.pv);
  }

  parseData(status, data, client) {
    return data;
  }
}

