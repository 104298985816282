import React, { useState, useEffect, useRef } from "react";
import bootstrap from "bootstrap";
import { Tran, tran } from "utils/language";
import { Button, Checkbox, Icon, Input, Message } from "components/shared";
import { ForgotPasswordDialog } from "../ForgotPasswordDialog";
import { DialogPresets } from "components/shared/Dialog";
import { BrandedDialog } from "components/shared/Dialog/comps";
import useDialog, { IDialog } from "components/shared/Dialog/useDialog";
import { SignupDialog } from "../Signup/SignupDialog";
import { useBind, useId } from "../../../utils/hooks";
import { performLogin } from "./loginLogic";
import { usePasswordValidation } from "../../common/PasswordForm/usePasswordValidation";
import { isLoaded, notYet } from "../../../capi/hooks";
import { Requirements } from "../../common/PasswordForm/Requirements";
import { RequestDialog } from "../../shared/requestUtils";
import { FlexWrapper, Toast } from "../../shared";
import { DateTime } from "../../shared/dateUtils";
import { CompleteSignupDialog } from "../Signup/CompleteSignupDialog";
import { getRuleIdf } from "utils/roles";
import { MFACodeDialog } from "./MFACodeDialog";
import { emptyObject } from "utils/constants";

type LoginLocalDialogProps = {
  dialog: IDialog,
  offerSignup: boolean,
};

// notYet to funkcja, a setState nie interpretuje ich jako wartości
const notYetCons = () => notYet;

export function LoginLocalDialog({ dialog, offerSignup }: LoginLocalDialogProps) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [newPasswordFor, setNewPasswordFor] = useState<typeof notYet | string>(notYetCons);

  const [mfaCode, setMfaCode] = useState<{ code?: string, endDate?: Date }>(emptyObject);
  const mfaTimeoutRef = useRef<NodeJS.Timeout>();
  
  const signupDialog = useDialog(SignupDialog);
  const forgotPasswordDialog = useDialog(ForgotPasswordDialog);

  const [error, setError] = useState<React.ReactNode>("");
  const formId = useId();
  
  const passwordExpired = typeof newPasswordFor === "string";
  const passwordValidationState = usePasswordValidation(newPasswordFor);
  
  const onSubmit = useBind(handleLogin).preventDefault();
  
  const completeDialog = useDialog(CompleteSignupDialog);
  const mfaCodeDialog = useDialog(MFACodeDialog).onClose(handleMfaCode);
  
  useEffect(() => {
    // reset błędów po zmianie loginu
    setNewPasswordFor(notYetCons);
    setError("");
  }, [login]);
  
  useEffect(() => {
    // po wprowadzeniu nowego kodu przez usera ponawiamy próbę logowania
    if (mfaCode.code)
      handleLogin();
  }, [mfaCode]);
  
  if (passwordExpired && !isLoaded(passwordValidationState.paramsRq))
    return <RequestDialog request={passwordValidationState.paramsRq} dialog={dialog} />;
  
  const buttons = [
    <Button preset="cancel" onClick={dialog.close} />,
    <Button preset="login" onClick={onSubmit} form={formId} />
  ];
  
  let newPasswordPart = null;
  if (passwordExpired) {
    newPasswordPart = <>
      <Input
        labelCls="custom-input__label--login"
        {...passwordValidationState.newPasswordProps}
        autoFocus={passwordExpired}
        fluid
        required
        icon={undefined}
        enablePasswordPreview
      />
  
      <Input
        labelCls="custom-input__label--login"
        {...passwordValidationState.confirmPasswordProps}
        fluid
        required
        icon={undefined}
        enablePasswordPreview
      />
      
      <Requirements {...passwordValidationState.requirementsProps} cls="login-form__pass-requirements" />
    </>;
  }

  return (
    <DialogPresets.BrandedDialog size="large" dialog={dialog} buttons={buttons}>
      <form className="login-form" id={formId}>
        <BrandedDialog.Heading>
          {tran("login.heading")}
        </BrandedDialog.Heading>

        {/* Nazwa biblioteki */}
        {!bootstrap.WZUW && 
          <h3 className="login-form__subheading">{bootstrap.title}</h3>
        }
        
        {passwordExpired && passwordValidationState.errors.length > 0
          ? <Message type="error" content={<div>{passwordValidationState.errors.map(el => <div>{el}</div>)}</div>} />
          : error && <Message type="error" content={error} />}

        <Input
          wrapperCls="custom-input--login"
          labelCls="custom-input__label--login"
          fluid
          required
          autoFocus={!passwordExpired}
          type="text"
          value={login}
          onChange={setLogin}
          label={tran("login.loginOrEmail")}
        />

        <Input
          wrapperCls="custom-input--login"
          labelCls="custom-input__label--login"
          fluid
          required
          type="password"
          value={password}
          onChange={setPassword}
          label={tran("login.password")}
        />
        
        {mfaCode && mfaCode.endDate &&
          <FlexWrapper alignItems="baseline" justifyContent="space-between" style={{ margin: "30px 0" }}>
            <div>
              <Icon name="shield-keyhole" />
              <span style={{ color: "var(--textLight)" }}>{tran("login.mfa.expiring")}</span>
            </div>
            <Countdown endDate={mfaCode.endDate} />
          </FlexWrapper>}
        
        {newPasswordPart}
        
        <FlexWrapper alignItems="baseline" columnOnMobile style={{ marginTop: 16 }}>
          <div>
            <div className="login-form__checkbox-wrapper">
              <Checkbox
                label={tran("login.btn.remember")}
                checked={remember}
                onClick={() => setRemember(prevVal => !prevVal)}
              />
            </div>
          </div>
          
          <FlexWrapper alignItems="start" flexDirection="column" style={{ marginLeft: -8 /* minus padding buttona */ }}>
            {offerSignup && !passwordExpired &&
              <Button
                className="m0"
                variant="text"
                type="button"
                icon="user-plus"
                onClick={signupDialog.open}
                content={tran("login.btn.noAccount")}
              />}
            {!passwordExpired &&
              <Button
                className="m0"
                variant="text"
                type="button"
                icon="lock"
                onClick={forgotPasswordDialog.open}
                children={tran("login.btn.forgot")}
              />}
          </FlexWrapper>
        </FlexWrapper>
      </form>
    </DialogPresets.BrandedDialog>
  );
  
  async function handleLogin() {
    if (!login || !password) {
      setError(tran("error.fillAllFields"));
      return;
    }
    
    if (passwordExpired && !passwordValidationState.validate())
      return;
    
    let result;
    try {
      result = await performLogin({ identifier: login, password, remember, mfaCode: mfaCode.code, newPassword: passwordExpired ? passwordValidationState.values.newPassword : undefined });
    }
    catch(err: any) {
      if (err.capiStatus && err.capiStatus < 500) {
        if (err.capiReason === "registration_incomplete") {
          completeDialog.openWith({ client: bootstrap.folks.client.withAuth(100, err.capiData.user_id, bootstrap.domain, password), skipTerms: true });
          return;
        }
        
        if (err.capiReason === "mfa_required: totp") {
          mfaCodeDialog.open();
          return
        }
        
        if (err.capiReason === "mfa_invalid") {
          setMfaCode(emptyObject);
          mfaCodeDialog.open();
          return;
        }
        
        setError(err.enduserMessage);
        return;
      }
      else {
        throw err;
      }
    }
    
    if (result.expired) {
      setNewPasswordFor(getRuleIdf(result.role!));
      setError(tran("login.err.expired"))
    }
    else if (result.expiry !== undefined) {
      Toast.emit(<Tran id="login.toast.password" search="<<1>>" replace={<DateTime at={result.expiry}/>} /> as any)
    }
  }

  function handleMfaCode(code?: string) {
    if (!code) return 
    
    const now = Date.now();
    const start = Math.floor(now / 30 / 1000) * 30;
    const end = start + 119;
    const endDate = new Date(end * 1000);
    
    setMfaCode({ code, endDate });
    
    clearTimeout(mfaTimeoutRef.current);
    mfaTimeoutRef.current = setTimeout(() => setMfaCode(emptyObject), (end * 1000) - now);
  }
}

const Countdown = React.memo(({ endDate }: { endDate: Date }) => {
  const [left, setLeft] = useState(() => endDate.getTime() - Date.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setLeft(endDate.getTime() - Date.now());
    });
    return () => clearInterval(interval);
  }, [endDate]);
  
  if (left > 0) {
    const s = Math.floor(Math.abs(left) / 1000);
    return `${Math.floor(s / 60).toString().padStart(2, "0")}:${(s % 60).toString().padStart(2, "0")}`;
  }
  
  return "00:00" as any;
});
