import { DialogPresets } from "components/shared/Dialog";
import type { IDialog } from "components/shared/Dialog/useDialog";
import React from "react";
import { Form } from "utils/forms";
import { tran, useLanguage } from "utils/language";
import type { Dictionary } from "utils/types";

export function MFACodeDialog({ dialog }: { dialog: IDialog }) {
  const { translate } = useLanguage();

  return (
    <DialogPresets.FormDialog
      dialog={dialog}
      size="medium"
      name="mfa-code"
      icon="shield-keyhole"
      title={tran("login.mfa.code")}
      savePreset="confirm"
      onSubmit={handleSubmit}>
      <p>{tran("login.mfa.methodInfo")}</p>

      <p style={{ color: "var(--textLight)", fontSize: "0.95em" }}>
        {tran("login.mfa.removeInfo")}
      </p>

      <Form.Input
        name="code"
        fluid
        placeholder={translate("users.profile.mfa.add.placeholder")}
        maxLength={6}
      />
    </DialogPresets.FormDialog>
  );

  function handleSubmit(values: Dictionary) {
    dialog.closeWith(values.code);
  }
}
