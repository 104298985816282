import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import moment, { Moment } from "moment";
import isEqualReact from "react-fast-compare";

import { useSignal } from "utils/hooks";
import { doNothing, emptyArray } from "utils/constants";
import { capiDateFromString } from "capi/client";

// Zlokalizowane formaty (localized formats):
// https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/

//
// Date
//

type DateProps = {
  at?: Date | moment.Moment | string | number | null | undefined,
  long?: boolean,
  dayOfTheWeek?: boolean,
  monthName?: boolean,
  timeInTitle?: boolean
};

// TODO: podobne parsowanie jak w DateOnly dla innych komponentów

export const DateOnly = React.memo(function DateOnly(props: DateProps) {
  if (props.hasOwnProperty("at") && (props.at === null || props.at === undefined || props.at === ""))
    return null;
  
  let { at, long, dayOfTheWeek = long, monthName = long, timeInTitle } = props;
  
  let m: Moment;
  if (typeof at === "string") {
    let f = "";
    switch (at.replace(digits, "#")) {
      case "########":
        f = "YYYYMMDD";
      case "##.##.####":
      case "#.#.####":
      case "#.##.####":
      case "##.#.####":
        f = f || "DD.MM.YYYY";
      case "####-##-##":
      case "####-#-#":
      case "####-#-##":
      case "####-##-##":
        f = f || "YYYY-MM-DD";
        m = moment(at, f);
        timeInTitle = false;
        break;
      case "####-##-## ##:##:##":
      case "####-##-## ##:##:##.###":
      case "####-##-## ##:##:##.######":
        m = moment(capiDateFromString(at));
        break;
      default:
        return <span className="display-contents unparsable-date">{at}</span>;
    }
  }
  else {
    try {
      if (at && ((at as any).hasTime === false))
        timeInTitle = false;
    }
    catch (e) {}
    m = moment(at);
  }
  
  const titleFormat = timeInTitle ? "LLLL" : "dddd, LL";
  const title = m.format(titleFormat);
  
  let format;
  if (dayOfTheWeek)
    format = monthName ? "dddd, LL" : "dddd, L";
  else
    format = monthName ? "LL" : "L";
   
  const result = format === "dddd, LL" ? title : m.format(format);
  
  return <time title={title} dateTime={m.format("yyyy-MM-DD")}>{result}</time>;
}, isEqualReact);

type TodayProps = {
  long?: boolean,
  dayOfTheWeek?: boolean,
  monthName?: boolean
};

export const Today = React.memo(function Today({ long, dayOfTheWeek = long, monthName = long }: TodayProps): JSX.Element {
  usePeriodicUpdates(true);
  
  const m = moment();
  const title = m.format("dddd, LL");
  
  let format;
  if (dayOfTheWeek)
    format = monthName ? "dddd, LL" : "dddd, L";
  else
    format = monthName ? "LL" : "L";
   
  const result = format === "dddd, LL" ? title : m.format(format);
  
  return <time title={title} dateTime={m.format("yyyy-MM-DD")}>{result}</time>;
}, isEqualReact);

const digits = /\d/g;

//
// Time
//

type TimeProps = {
  at?: Date | moment.Moment | string | null | undefined,
  seconds?: boolean
};

export const Time = React.memo(function Time(props: TimeProps) {
  if (props.hasOwnProperty("at") && (props.at === null || props.at === undefined))
    return null;
  
  return moment(props.at).format(props.seconds ? "LTS" : "LT") as any;
}, isEqualReact);

//
// DateTime
//

type DateTimeProps = {
  at?: Date | moment.Moment | string | null | undefined,
  dayOfTheWeek?: boolean,
  monthName?: boolean,
  long?: boolean,
  seconds?: boolean,
};

export const DateTime = React.memo(function DateTime(props: DateTimeProps) {
  if (props.hasOwnProperty("at") && (props.at === null || props.at === undefined))
    return null;
  
  const { long, dayOfTheWeek = long, monthName = long, seconds } = props;
  const m = moment(props.at);
  
  let format;
  if (dayOfTheWeek) {
    if (seconds)
      format = monthName ? "dddd, LL LTS" : "dddd, L LTS";
    else
      format = monthName ? "dddd, LL LT" : "dddd, L LT";
  }
  else {
    if (seconds)
      format = monthName ? "LL LTS" : "L LTS";
    else
      format = monthName ? "LL LT" : "L LT";
  }
  
  const title = m.format("LLLL");

  return <time title={title} dateTime={m.toISOString()}>{m.format(format)}</time>;
}, isEqualReact);

//
// TimeAgo
//

type TimeAgoProps = {
  at: Date | moment.Moment | string | null | undefined,
};

export const TimeAgo = React.memo(function TimeAgo(props: TimeAgoProps) {
  usePeriodicUpdates(true);
  
  if (props.hasOwnProperty("at") && (props.at === null || props.at === undefined))
    return null;
  
  const m = moment(props.at);

  const title = m.format("LLLL");
  
  return <time title={title} dateTime={m.toISOString()}>{m.fromNow()}</time>;
}, isEqualReact);

type DaysAgoProps = {
  at: Date | moment.Moment | string | null | undefined,
}

export const DaysAgo = React.memo(function DaysAgo(props: DaysAgoProps) {
  usePeriodicUpdates(true);
  
  if (props.hasOwnProperty("at") && (props.at === null || props.at === undefined)) {
    return null;
  }

  const m = moment(props.at);
  const today = moment().startOf("day");
  const target = m.clone().startOf("day");

  const diff = target.diff(today, "days");
  const absDiff = Math.abs(diff);

  let displayText: string;

  if (absDiff > 31) {
    displayText = m.fromNow();
  } else {
    switch (diff) {
      case 0:
        displayText = "dzisiaj";
        break;
      case -1:
        displayText = "wczoraj";
        break;
      case 1:
        displayText = "jutro";
        break;
      default:
        displayText = diff < 0
          ? `${absDiff} dni temu`
          : `za ${diff} dni`;
    }
  }

  const title = m.format("dddd, LL");

  return <time title={title} dateTime={m.toISOString()}>{displayText}</time>;
}, isEqualReact);

// TODO: opt, sygnalizować zmiany tylko gdy coś się w praktyce zmienia

function usePeriodicUpdates(enabled: boolean) {
  type State = {
    onMount: () => () => void
  }
  
  const signal = useSignal();
  const stateRef = useRef(null as any as State);
  
  if (stateRef.current === null) {
    stateRef.current = {
      onMount: () => {
        registerForUpdates(signal);
        return () => {
          unregisterForUpdates(signal);
        }
      }
    }
  }
  
  // eslint-disable-next-line
  useEffect(enabled ? stateRef.current.onMount : doNothing, emptyArray);
}

const SIGNALS = new Set<() => void>();
let timer: number | null = null;

function registerForUpdates(signal: () => void) {
  SIGNALS.add(signal);
  if (timer === null)
    timer = setInterval(ReactDOM.unstable_batchedUpdates, 30000, update) as any as number;
}

function unregisterForUpdates(signal: () => void) {
  SIGNALS.delete(signal);
  if (SIGNALS.size === 0 && timer !== null) {
    clearInterval(timer);
    timer = null;
  }
}

function update() {
  if (document.visibilityState !== "hidden") {
    for (let signal of SIGNALS) signal();
  }
}

export function atStartOfDay(): Date;
export function atStartOfDay(date: Date): Date;
export function atStartOfDay(date: undefined): undefined;
export function atStartOfDay(date?: Date) {
  if (arguments.length > 0 && date === undefined)
    return undefined;
  let d = date ? new Date(date) : new Date();
  d.setHours(0, 0, 0, 0);
  return d;
}

// FIXME: @marcin żadne API nie powinno polegać na tym, bo serwer używa innej precyzji niż klient, wszystko powinno być 1 <= x < 2
export function atEndOfDay(): Date;
export function atEndOfDay(date: Date): Date;
export function atEndOfDay(date: undefined): undefined;
export function atEndOfDay(date?: Date) {
  if (arguments.length > 0 && date === undefined)
    return undefined;
  let d = date ? new Date(date) : new Date();
  d.setHours(23, 59, 59, 999); // chcielibyśmy 999.999, ale się nie da...
  return d;
}

// UWAGA: a to niestety bardzo nie pomoże, bo w przeciwieńswie do atEndOfDay nie jest idempotentne
//        więc nie nadaje się do użycia w formularzach edycyjnych
export function atStartOfNextDay(): Date;
export function atStartOfNextDay(date: Date): Date;
export function atStartOfNextDay(date: undefined): undefined;
export function atStartOfNextDay(date?: Date) {
  if (arguments.length > 0 && date === undefined)
    return undefined;
  let d = new Date((date ? date.getTime() : Date.now()) + 86400000);
  d.setHours(0, 0, 0, 0);
  return d;
}
